.grid {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -74px;

    &__item {
        padding: 0 74px;
        max-width: 50%;
        flex: 0 0 50%;
        width: 100%;

        &__content {
            border-radius: 40px;
            background-color: $white;
            padding: 98px 46px;
            position: relative;
            box-shadow: 0px 12px 64.78px 14.22px rgba(47, 94, 86, 0.08);
            

            &:before {
                content: "";
                position: absolute;
                top: -30px;
                left: 46px;
                background-image: url(../images/icons/grid-icon.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% 100%;
                width: 80px;
                height: 60px;
            }

            * {
                font-family: $font2;
                font-style: italic;
            }
        }

        &:not(:last-of-type) {
            margin-bottom: 74px;
        }
    }

    @media screen and (max-width: $breakPointDesktop) {
        margin: 0 -37px;

        &__item {
            border-radius: 20px;
            padding: 0 37px;

            &__content {
                padding: 50px 25px;

                &:before {
                    top: -15px;
                    left: 23px;
                    width: 40px;
                    height: 30px;
                }
            }

            &:not(:last-of-type) {
                margin-bottom: 37px;
            }
        }
    }
}