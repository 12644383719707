#info {
    position: fixed;
    right: 50px;
    bottom: 25px;
    width: 92px;
    height: 92px;
    z-index: 9998
}

#info .open-slider {
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    cursor: pointer
}

#info .open-slider .hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    background-position: right center;
    background-repeat: no-repeat;
    background-size: auto 100%;
    opacity: 0;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s
}

#info .open-slider:hover .hover {
    opacity: 1
}

#info .slider {
    border-radius: 16px;
    background-color: #fff;
    -webkit-box-shadow: 0 0 23px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 23px 0 rgba(0, 0, 0, .05);
    position: absolute;
    right: 0;
    top: 0;
    width: 640px;
    opacity: 0;
    visibility: hidden;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    text-align: center;
    padding: 0;
    overflow: hidden;
    -webkit-transform: translateY(100%);
    -moz-transform: translateY(100%);
    -ms-transform: translateY(100%);
    transform: translateY(100%);
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s
}

#info .slider .item {
    -webkit-box-flex: 1;
    -webkit-flex: 1;
    -moz-box-flex: 1;
    -ms-flex: 1;
    flex: 1;
    padding: 20px 15px;
    position: relative;
    min-height: 60px;
    display: -webkit-box;
    display: -webkit-flex;
    display: -moz-box;
    display: -ms-flexbox;
    display: flex;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -moz-box-pack: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-box-orient: vertical;
    -webkit-box-direction: normal;
    -webkit-flex-direction: column;
    -moz-box-orient: vertical;
    -moz-box-direction: normal;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-align: center;
    -webkit-align-items: center;
    -moz-box-align: center;
    -ms-flex-align: center;
    align-items: center;
    z-index: 0
}

#info .slider .item:before {
    content: "";
    position: absolute;
    left: 0;
    right: 0;
    top: -50%;
    bottom: -50%;
    opacity: 0;
    z-index: -1;
    background: #2b524c;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s
}

#info .slider .item .img {
    width: 30px;
    min-height: 30px;
    margin: 0 auto 5px;
    background-size: 100% auto;
    background-position: center top;
    background-repeat: no-repeat
}

#info .slider .item .img.on-hover {
    position: absolute;
    left: 0;
    right: 0;
    top: 20px;
    opacity: 0
}

#info .slider .item p {
    font-size: 17px;
    line-height: 1.4;
    color: #3cce88
}

#info .slider .item:not(:last-of-type):after {
    content: "";
    position: absolute;
    right: 0;
    width: 1px;
    height: 60px;
    top: 0;
    bottom: 0;
    margin: auto;
    background: #3cce88
}

#info .slider .item.has-text {
    font-size: 25px;
    font-weight: 700;
    line-height: .96
}

#info .slider .item.has-text.show-mobile {
    display: none
}

#info .slider .item.btn-close {
    max-width: 100px
}

#info .slider .item.btn-close .img {
    width: 25px;
    min-height: 25px;
    margin: auto
}

#info .slider .item.btn-close .img.on-hover {
    top: 0;
    bottom: 0;
    height: 25px
}

#info .slider .item:hover:before {
    opacity: 1
}

#info .slider .item:hover .on-hover {
    opacity: 1
}

#info .slider .item:hover p {
    color: #fff
}

#info .slider .item:hover:after {
    opacity: 0
}

#info .slider.open {
    -webkit-transform: translateY(0);
    -moz-transform: translateY(0);
    -ms-transform: translateY(0);
    transform: translateY(0);
    opacity: 1;
    visibility: visible
}

#info .rendez {
    position: absolute;
    bottom: 0;
    right: 0;
    width: 340px;
    background: #fff;
    -webkit-box-shadow: 0 0 23px 0 rgba(0, 0, 0, .05);
    box-shadow: 0 0 23px 0 rgba(0, 0, 0, .05);
    border-radius: 25px;
    overflow: hidden;
    overflow-y: auto;
    max-height: 0
}

#info .rendez .mod_tm_ajax_contact_form .mod_tm_ajax_contact_form_message>span {
    display: none
}

#info .rendez .inner-header {
    padding: 16px 30px;
    padding-right: 45px;
    background: #f4f4f4;
    position: relative
}

#info .rendez .inner-header h3 {
    background-repeat: no-repeat;
    background-position: left center;
    background-size: 30px 32px;
    padding-left: 45px;
    font-size: 17px;
    line-height: 32px;
    color: #3cce88;
    font-weight: 400;
    margin: 0
}

#info .rendez .inner-header .btn-close {
    position: absolute;
    top: 0;
    right: 15px;
    bottom: 0;
    width: 30px;
    height: 30px;
    margin: auto;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 15px auto;
    cursor: pointer
}

#info .rendez .inner-content {
    padding: 20px 30px
}

#info .rendez .inner-content p {
    line-height: 28px
}

#info .rendez .inner-content form {
    text-align: center;
    margin-top: 15px
}

#info .rendez .inner-content input {
    border: 1px solid #ccc;
    border-radius: 25px;
    font-size: 17px;
    font-family: $font;
    color: #333;
    line-height: 38px;
    min-height: 38px;
    padding: 0 20px;
    width: 100%;
    margin: 5px 0;
    outline: none
}

#info .rendez .inner-content input::-webkit-input-placeholder {
    font-size: 17px;
    font-family: $font;
    color: #ccc;
    line-height: 38px
}

#info .rendez .inner-content input::-moz-placeholder {
    font-size: 17px;
    font-family: $font;
    color: #ccc;
    line-height: 38px
}

#info .rendez .inner-content input::-ms-input-placeholder {
    font-size: 17px;
    font-family: $font;
    color: #ccc;
    line-height: 38px
}

#info .rendez .inner-content input::placeholder {
    font-size: 17px;
    font-family: $font;
    color: #ccc;
    line-height: 38px
}

#info .rendez .inner-content button {
    width: auto;
    height: auto;
    margin-top: 25px;
    display: inline-block;
    border: none;
    text-align: center;
    font-size: 17px;
    line-height: 20px;
    padding: 14px 28px;
    border-radius: 25px;
    -webkit-transition: all 0.3s;
    -moz-transition: all 0.3s;
    transition: all 0.3s;
    background-color: #3cce88;
    color: #fff;
    white-space: normal;
    text-transform: none
}

#info .rendez .inner-content button:hover {
    background-color: #2b524c
}

#info .rendez .inner-footer {
    padding: 0 30px 15px
}

#info .rendez .inner-footer p {
    font-size: 14px;
    font-style: italic;
    line-height: 110%
}

#info .rendez.open {
    max-height: 470px;
    max-height: 100vh;
    max-height: -moz-calc(100vh - 133px);
    max-height: calc(100vh - 133px)
}

@media screen and (max-width:1000px) {
    #info {
        left: 0;
        right: 0;
        width: auto;
        bottom: 0;
        height: auto
    }

    #info .slider {
        -webkit-transform: none;
        -moz-transform: none;
        -ms-transform: none;
        transform: none;
        opacity: 1;
        visibility: visible;
        position: relative;
        width: 100%;
        border-radius: 0;
        padding: 0
    }

    #info .slider .item {
        height: auto;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 25%;
        -moz-box-flex: 1;
        -ms-flex: 1 1 25%;
        flex: 1 1 25%
    }

    #info .slider .item .img {
        margin: auto
    }

    #info .slider .item .img.on-hover {
        top: 20px
    }

    #info .slider .item p {
        display: none
    }

    #info .slider .item.has-text {
        display: none
    }

    #info .slider .item.has-text p {
        display: block
    }

    #info .slider .item.has-text.show-mobile {
        display: -webkit-box;
        display: -webkit-flex;
        display: -moz-box;
        display: -ms-flexbox;
        display: flex;
        -webkit-box-flex: 1;
        -webkit-flex: 1 1 60%;
        -moz-box-flex: 1;
        -ms-flex: 1 1 60%;
        flex: 1 1 60%;
        padding: 20px 25px
    }

    #info .slider .item.has-text.show-mobile p {
        margin: 0;
        color: #fff
    }

    #info .slider .item.has-text.show-mobile:before {
        background: #3cce88;
        opacity: 1
    }

    #info .slider .item.has-text.show-mobile:hover:before {
        background: #2b524c
    }

    #info .slider .item:nth-last-of-type(2):after,
    #info .slider .item:nth-last-of-type(3):after {
        display: none
    }

    #info .slider .item.btn-close {
        display: none !important
    }
}