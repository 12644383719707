.services {
    .item {
        display: flex;
        flex-direction: column;
        align-items: center;

        img {
            width: 100%;
            height: 113px;
            margin-bottom: $mbL;

            @media screen and (max-width: $breakPointDesktop) {
                height: 55px;
            }
        }

        h4 {
            margin-bottom: $mbL;
        }

        p {
            min-height: $lineHeightTextL;
            text-align: center;
        }

        .lien {
            margin-top: $lineHeightText;
        }

        h2 {
            margin-bottom: $lineHeightText;
            font-size: 83px;
            line-height: 1;

            @media screen and (max-width: $breakPointDesktop) {
                font-size: 42px;
            }
        }

        @media screen and (max-width: $breakPointTablet) {
            margin-bottom: 50px;
        }
    }

    @media screen and (max-width: $breakPointTablet) {
        padding-bottom: 0;
    }
}