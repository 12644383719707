#nav {
    padding: 30px 0;
    position: sticky;
    top: 0;
    background-color: $white;
    z-index: 9999;
    box-shadow: 0px 2px 5.58px 0.42px rgba(22, 22, 22, 0.08);
    
    .logo {
        display: flex;
        align-items: center;

        a {
            display: block;
            flex: 1;
        }
        img {
            width: 100%;
            max-width: 260px;

            @media screen and (max-width: $breakPointDesktop) {
                max-width: 138px;
            }
        }
    }

    .menu {
        display: flex;
        justify-content: space-between;
        align-items: center;

        li {
            list-style: none;
            position: relative;

            a:not(.btn) {
                font-size: $sizeText;
                line-height: 1.087;
                font-weight: 400;
                color: $primary;
                cursor: pointer;
                position: relative;

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    right: 0;
                    bottom: -7px;
                    height: 2px;
                    background-color: $primary;
                    transform: translateY(2px);
                    visibility: hidden;
                    opacity: 0;
                    transition: all .3s;
                }
            }

            a.btn {
                font-size: $sizeText;
                line-height: 1.087;
                font-weight: 400;
                padding: 23px 35px;
                border-radius: 40px;

                @media screen and (max-width: $breakPointDesktop) {
                    padding: 12px 18px;
                    border-radius: 20px;
                }
            }

            &.parent {
                position: relative;

                ul {
                    position: absolute;
                    top: 100%;
                    left: calc(50%);
                    padding: 70px 95px;
                    padding-top: 120px;
                    opacity: 0;
                    visibility: hidden;
                    z-index: 1;
                    min-width: max-content;
                    min-width: 500px;
                    transform: translateX(-50%);
                    
                    &:before {
                        content: "" ;
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 50px;
                        bottom: 0;
                        background-color: $white;
                        z-index: -1;
                        border-radius: 40px;
                        box-shadow: 0px 6px 12.3px 2.7px rgba(22, 22, 22, 0.05);
                    }

                    @media screen and (max-width: $breakPointDesktop) {
                        padding: 45px;
                        padding-top: 60px;
                        min-width: 250px;

                        &:before {
                            border-radius: 20px;
                            top: 25px;
                        }
                    }

                    li {
                        
                        a {
                            font-family: $font;
                            font-size: 24px;
                            line-height: 3.13;
                            margin-bottom: 0;
                            font-weight: 400;
                            color: $primary;
                            transition: all .3s;

                            &:after {
                                display: none;
                            }

                            @media screen and (max-width: $breakPointDesktop) {
                                font-size: 14px;
                            }
                        }

                        &:hover {
                            a {
                                color: $second;
                            }
                        }
                        a.active {
                            color: $second;
                        }
                    }

                    .menu-title {
                        display: none;
                    }

                    &#menu-credit {
                        position: fixed;
                        left: 0;
                        right: 0;
                        z-index: 0;
                        top: 0;
                        padding-top: 140px;
                        transform: none;

                        @media screen and (max-width: $breakPointDesktop) {
                            padding-top: 90px;
                        }

                        .credit > .row {
                            margin: 0;
                        }

                        &:before {
                            display: none;
                        }
                    
                        .col-lg-10 {
                            background-color: $primary;
                            border-radius: 8px;
                            padding: 40px 30px;
                            background-color: $white;
                            border-radius: 18px;
                            box-shadow: 0px 6px 12.3px 2.7px rgba(22, 22, 22, 0.05);

                            .col-lg-4 {
                                position: relative;

                                &:first-of-type {
                                    .item:first-of-type .block .content-img img {
                                        width: 70px;

                                        @media screen and (max-width: $breakPointDesktop) {
                                            width: 35px;
                                        }
                                    }
                                }
                                
                                &:not(:last-of-type):after {
                                    content: "";
                                    position: absolute;
                                    width: 1px;
                                    right: 0;
                                    top: 80px;
                                    bottom: 80px;
                                    background-image: url(../images/icons/ligne.png);
                                    background-position: center top;
                                    background-repeat: no-repeat;
                                    background-size: auto 100%;

                                    @media screen and (max-width: $breakPointDesktop) {
                                        top: 40px;
                                        bottom: 40;
                                    }

                                    @media screen and (max-width: $breakPointTablet) {
                                        display: none;
                                    }
                                }
                            }
                        }
                    
                        .col-lg-6 {
                            display: flex;
                    
                            .item {
                                flex: 0 0 50%;
                                max-width: 50%;
                            }
                        }
                    
                        .item {
                            padding: 0;

                            &:not(:first-of-type) {
                                .block {
                                    padding-top: 40px;

                                    @media screen and (max-width: $breakPointDesktop) {
                                        padding-top: 20px;
                                    }
                                }
                            }
                        }
                    
                        .block {
                            display: flex;
                            padding: 80px 25px;
                            display: flex;
                            justify-content: flex-start;

                            @media screen and (max-width: $breakPointDesktop) {
                                padding: 40px 25px;
                            }

                            .content-img {
                                margin-right: 40px;

                                @media screen and (max-width: $breakPointDesktop) {
                                    margin-right: 20px;
                                }

                                img {
                                    height: 60px;
                                    width: 60px;
                                    position: relative;
                                    top: 10px;

                                    @media screen and (max-width: $breakPointDesktop) {
                                        height: 30px;
                                        width: 30px;
                                    }
                                }
                            }
                    
                            h3 {
                                font-family: $font;
                                font-size: $sizeText;
                                line-height: 2.769;
                                font-weight: 700;
                                color: $primary;
                                position: relative;
                                margin: 0;
                                display: flex;
                                align-items: center;

                                img {
                                    width: 30px;
                                    margin-left: -55px;
                                    margin-right: 20px;
                                }
                            }
                    
                            a {
                                font-family: $font;
                                font-size: $sizeText;
                                line-height: 2.769;
                                font-weight: 400;
                                display: block;
                                transition: all .3s;
                                
                                &:after {
                                    display: none;
                                }

                                &:hover,
                                &.active {
                                    color: $second;
                                }
                            }

                            &.last {
                                text-align: center;

                                img {
                                    margin-bottom: 30px;
                                }

                                p {
                                    text-align: left;

                                    span {
                                        color: $second;
                                    }
                                }

                                .btn {
                                    display: inline-block;
                                    font-weight: 700;
                                    padding: 40px 12%;
                                    line-height: 1.659;
                                    border-radius: 64px;
                                    width: 95%;
                                    margin: auto;
                                    margin-top: $lineHeightText;

                                    @media screen and (max-width: $breakPointDesktop) {
                                        padding: 20px 12%;
                                    }
                                    
                                    &:hover {
                                        color: $primary;
                                    }
                                }
                            }
                        }
                    }
                }
            }

            &:hover {

                &.parent {
                    ul {
                        opacity: 1;
                        visibility: visible;
                    }
                }

                a:after {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }

            &.active {
                a:after {
                    transform: translateY(0);
                    opacity: 1;
                    visibility: visible;
                }
            }
        }
    }

    .lang {
        
        &.col-lg-1 {
            max-width: 70px;
            margin: auto;

            @media screen and (max-width: $breakPointDesktop) {
                max-width: 55px;
            }
        }

        .content {
            height: 30px;
            position: relative;

            @media screen and (max-width: $breakPointDesktop) {
                height: 23px;
            }

            ul.lang {
                max-height: 60px;
                overflow: hidden;
                transition: all .3s;
                list-style: none;
                position: absolute;
                top: -15px;
                left: -15px;
                right: -15px;
                background-color: transparent;
                padding: 15px;
                border-radius: 8px;

                a:hover {
                    color: $second;
                }

                li:not(.active) {
                    margin: 5px 0;

                    a {
                        cursor: pointer !important;
                    }
                }

                &:after {
                    content: "";
                    position: absolute;
                    right: 10px;
                    top: 25px;
                    width: 8px;
                    height: 8px;
                    border: 2px solid $primary;
                    border-right: none;
                    border-bottom: none;
                    transform: rotate(-135deg);
                }

                &:hover {
                    max-height: 30px * 10;
                    background-color: $white;
                    box-shadow: 0px 6px 12.3px 2.7px rgba(22, 22, 22, 0.05);
                }

                @media screen and (max-width: $breakPointDesktop) {
                    max-height: 40px;
                    top: -12px;

                    &:after {
                        top: 20px;
                    }

                    &:hover {
                        max-height: 30px * 5;
                    }
                }
            }
        }
        
    }

    @media screen and (max-width: $breakPointTablet) {
        padding: 20px 0;
        
        .col-lg-1.lang {
            position: fixed;
            right: 70px;
            top: -15px;
            height: 66px;
            width: 70px;
            display: flex;
            align-items: center;

            .content ul.lang {
                position: relative;
                padding: 15px;
                padding-right: 30px;
                top: auto;
                left: auto;
                right: auto;
                z-index: 0;

                &:before {
                    content: "";
                    position: absolute;
                    left: 5px;
                    right: 5px;
                    top: 5px;
                    bottom: 5px;
                    z-index: -1;
                    background-color: $white;
                    box-shadow: 0px 6px 12.3px 2.7px rgba(22, 22, 22, 0.05);
                    opacity: 0;
                }

                &:after {
                    top: 20px;
                    right: 15px;
                }

                &.is-open {
                    max-height: 400px;

                    &:before {
                        opacity: 1;
                    }
                }
            }
        }

        .logo {
            display: flex;
            justify-content: space-between;
            align-items: center;
        }

        .btn-menu {
            position: relative;
            cursor: pointer;
            width: 20px;
            height: 16.5px;

            span {
                position: absolute;
                left: 0;
                width: 17px;
                margin: auto;
                top: 0;
                bottom: 0;
                height: 3.5px;
                background-color: $primary;
                transition: all .3s;

                &:before {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 20px;
                    margin: auto;
                    top: -6.5px;
                    height: 3.5px;
                    background-color: $primary;
                    transition: all .3s;
                }

                &:after {
                    content: "";
                    position: absolute;
                    left: 0;
                    width: 13px;
                    margin: auto;
                    bottom: -6.5px;
                    height: 3.5px;
                    background-color: $primary;
                    transition: all .3s;
                }
            }

        }

        .content-menu {
            position: fixed;
            top: 66px;
            left: 0;
            right: 0;
            bottom: 0;
            padding: 45px 30px;
            overflow: hidden;
            overflow-y: auto;
            max-height: calc(100vh - 66px);
            background-color: $white;
            transform: translateX(-50%);
            opacity: 0;
            visibility: hidden;
            transition: all .3s;

            .menu {
                display: block;

                & > li {
                    position: relative;
                    padding: 15px 0;

                    &:first-of-type:before {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        top: 0;
                        height: 1px;
                        background-image: url(../images/icons/ligne-top.png);
                        background-position: left center;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                    }
                    
                    
                    &:not(.last):after {
                        content: "";
                        position: absolute;
                        left: 0;
                        right: 0;
                        bottom: 0;
                        height: 1px;
                        background-image: url(../images/icons/ligne-top.png);
                        background-position: left center;
                        background-repeat: no-repeat;
                        background-size: 100% auto;
                    }

                    &.last {
                        padding: 60px 0;
                        text-align: center;

                        .btn {
                            background-color: $primary;
                            color: $white;
                        }
                    }

                    a {
                        font-size: 23px;
                        line-height: 30px;
                        margin: 0;
                    }

                    &.parent {
                        a {
                            position: relative;
                            display: block;

                            &:after {
                                content: "";
                                position: absolute;
                                right: 0;
                                top: 0;
                                bottom: 0;
                                width: 9px;
                                height: 9px;
                                border-left: 1px solid $primary;
                                border-bottom: 1px solid $primary;
                                transform: rotate(-45deg);
                                visibility: visible;
                                opacity: 1;
                                left: auto;
                                margin: auto;
                                background: transparent;
                            }
                        }

                        & > ul {
                            position: relative;
                            padding: 0;
                            max-height: 0;
                            overflow: hidden;
                            opacity: 1;
                            visibility: visible;
                            left: auto;
                            transform: none;
                            transition: all .3s;

                            &:before {
                                display: none;
                            }

                            li {
                                a {
                                    font-size: $sizeText;
                                    line-height: $lineHeightText;

                                    &:after {
                                        display: none;
                                    }
                                }

                                &:not(:last-of-type) {
                                    margin-bottom: $lineHeightText;
                                }
                            }

                            &#menu-credit {
                                position: relative;
                                padding-top: 0;

                                .container {
                                    padding: 0;
                                }

                                .col-lg-10 {
                                    background-color: transparent;
                                    box-shadow: none;
                                    padding: 0;
                                }

                                .block.last {
                                    display: none;
                                }
                            }
                        }
                        
                        &.is-open {
                            a:after {
                                transform: rotate(135deg);
                            }

                            ul, ul#menu-credit {
                                max-height: 1000px;
                                padding-top: 30px;
                            }
                        }

                    }
                }
            }
        }

        &.open {
            .btn-menu {
                span {
                    background-color: transparent;

                    &:before {
                        width: 20px;
                        top: 0;
                        bottom: 0;
                        transform: rotate(45deg);
                        height: 2px;
                    }

                    &:after {
                        width: 20px;
                        bottom: 0;
                        top: 0;
                        transform: rotate(-45deg);
                        height: 2px;
                    }
                }
            }

            .content-menu {
                opacity: 1;
                visibility: visible;
                transform: translateX(0);
            }
        }
    }
}