#footer {
    background-color: $white;
    padding: 70px 0;

    @media screen and (max-width: $breakPointTablet) {
        padding: 60px 0;
    }

    .logo {
        margin-bottom: 40px;

        img {
            height: 30px;
        }
    }

    .item {
        a {
            display: block;
            margin-bottom: $lineHeightText;
        }

        br {
            margin-bottom: $lineHeightText;
        }

        @media screen and (max-width: $breakPointTablet) {
            &:not(:last-of-type) {
                margin-bottom: 30px;
            }
        }

        &.newsLetter {
            position: relative;

            .pictos {
                margin-top: 30px;

                a {
                    display: inline-block;
                    margin: 0;
                    margin-right: 13px;
        
                    img {
                        width: 22px;
                        height: 20px;
                    }
                }
            }

            @media screen and (max-width: $breakPointTablet) {
                max-width: 73%;
            }

            p {
                margin-bottom: $lineHeightText;
            }

            input {
                outline: none;
                width: 100%;
                font-family: $font;
                font-weight: 400;
                font-size: $sizeText;
                line-height: $lineHeightText;
                border: none;
                border-bottom: 2px solid $primary;
                padding: 0 0 5px;
                padding-right: 20px;
                border-radius: 0;

                &::placeholder {
                    color: $primary-light;
                }
            }

            .btn-submit {
                position: absolute;
                right: 0;
                top: 0;
                bottom: 0;
                width: 20px;
                background-color: transparent;
                background: url('../images/icons/send.svg');
                border: none;
                background-position: center;
                background-repeat: no-repeat;
                background-size: 22px auto;
            }
        }
    }

    
    .copyright {
        margin-top: 80px;

        @media screen and (max-width: $breakPointTablet) {
            margin-top: 60px;
        }

        #pe_stars > span::before {
            color: $primary !important;
        }

        #pe_rating.pe_g .pe_u {
            font-family: $font;
            font-weight: 400;
            font-size: $sizeTextSmall;
            line-height: $sizeTextSmall;
            color: $primary;
        }

        .text-center > p {
            margin-top: $sizeTextSmallM;
            font-size: $sizeTextSmall;
            line-height: $sizeTextSmall;
        }
    }
}