.team-item {
    .inner-item {
        position: relative;

        .img-team {
            border-radius: 50%;
            position: relative;
            overflow: hidden;
            padding-bottom: 100%;

            img {
                position: absolute;
                top: 50%;
                left: 50%;
                transform: translate(-50%, -50%);
            }
        }

        .linkedin {
            position: absolute;
            width: 66px;
            height: 66px;
            background-color: $secondLight;
            background-image: url(../images/icons/linkdin.svg);
            background-repeat: no-repeat;
            background-position: center;
            background-size: 30px auto;
            border-radius: 50%;
            bottom: 10px;
            right: 50px;

            @media screen and (max-width: $breakPointDesktop) {
                width: 33px;
                height: 33px;
                bottom: 5px;
                right: 25px;
                background-size: 15px auto;
            }
        }
    }
    

    .infos {
        margin-top: $mb;

        p {
            margin: 0;
        }
    }

    &.col-md-3 {
        &:last-of-type {
            margin-bottom: 0 !important;
        } 
    }
}