.input,
select,
input:not([type=submit]),
textarea {
    border-radius: 46px;
    border: 2px solid $primary;
    background-color: $white;
    font-size: $sizeText;
    line-height: $lineHeightText;
    text-align: center;
    margin-bottom: $mbS;
    width: 100%;
    padding: 25px;
    outline: none;
    resize: none;

    &::placeholder {
        color: $primary-light;
    }

    &.big {
        border-radius: 64px;
        font-size: $sizeH4;
        line-height: 0.482;
        padding: 35px 50px;
        border: 3px solid $primary;
    }

    @media screen and (max-width: $breakPointDesktop) {
        border: 1px solid $primary;
        padding: 15px;
        
        &.big {
            border: 1.5px solid $primary;
            padding: 20px 50px;
        }
    }

    &.error {
        border-color: $danger;
    }

    &+label.error {
        color: $danger;
        position: absolute;
        right: 0;
        bottom: 0px;
        transform: translateY(100%);
    }
}

select {
    appearance: none;
    text-align: left;
}

.radio {
    min-height: 50px;
    display: flex;
    align-items: center;
    cursor: pointer;

    label {
        display: block;
        padding-left: 76px;
        position: relative;

        &:before {
            content: "";
            position: absolute;
            top: 50%;
            transform: translateY(-50%);
            left: 0;
            border: 2px solid $primary;
            border-radius: 50%;
            width: 50px;
            height: 50px;
            background-color: $white;
            transition: all .3s;
        }
    }

    input {
        display: none;

        &:checked {
            background-color: $primary;

            & + label {
                &:before {
                    background-color: $primary;
                }
            }
        }
    }


    @media screen and (max-width: $breakPointDesktop) {
        min-height: 25px;

        label {
            padding-left: 38px;

            &:before {
                width: 25px;
                height: 25px;
            }
        }
    }
}

.checkBox {

    input {
        display: none;

        &:checked {
            & + label {
                &:after {
                    opacity: 1;
                }
            }
        }
    }
    label {
        position: relative;
        display: block;
        padding: 10px 0;
        padding-left: 75px;
        cursor: pointer;

        &:before {
            content: "";
            position: absolute;
            left: 0;
            top: 0;
            bottom: 0;
            margin: auto;
            width: 42px;
            height: 42px;
            border: 2px solid $primary;
            background-color: $white;
            border-radius: 8px;
        }

        &:after {
            content: "";
            position: absolute;
            top: 0;
            left: 10px;
            bottom: 0;
            margin: auto;
            width: 21px;
            height: 10px;
            border: 3px solid $second;
            border-left: none;
            border-bottom: none;
            transform: rotate(135deg);
            opacity: 0;
            transition: all .3s;
        }

       

        @media screen and (max-width: $breakPointDesktop) {
            padding: 5px 0;
            padding-left: 30px;
            
            &:before {
                border: 1px solid $primary;
                width: 20px;
                height: 20px;
                border-radius: 4px;
            }

            &:after {
                border-width: 1.5px;
                width: 10px;
                left: 5px;
                height: 5px;
            }
        }

    }
}

.control-group.field-spacer {
    display: none;
}

.control-group {
    display: flex;
    flex-wrap: wrap;
    text-align: left;
    position: relative;

    label {
        a {
            color: $second;
        }
    }

    .control-label {
        max-width: 30%;
        flex: 0 0 30%;
        padding-right: 15px;

        label {
            font-size: $sizeText;
            line-height: $lineHeightText;
            display: block;
            padding: 25px;

            @media screen and (max-width: $breakPointDesktop) {
                padding: 15px;
            }

            .form-control-feedback {
                display: none;
            }
        }
    }

    .controls {
        max-width: 70%;
        flex: 0 0 70%;
        position: relative;
        

        .col-lg-4 {
            position: relative;
        }

        input, textarea {
            margin-bottom: 0;
            text-align: left;
        }

        &.has-danger {
            input, textarea {
                border-color: $danger;
            }
        }

        &.radios {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            .radio {
                max-width: 25%;
                flex: 0 0 25%;
            }

            .full {
                flex: 0 0 100%;
                max-width: 100%;
                margin-top: 40px;

                @media screen and (max-width: $breakPointDesktop) {
                    margin-top: 20px;
                }
            }
        }

        .chosen-single {
            border-radius: 46px;
            border: 2px solid $primary;
            background-color: $white;
            font-size: $sizeText;
            line-height: $lineHeightText;
            text-align: center;
            margin-bottom: $mbS;
            width: 100%;
            padding: 25px;
            outline: none;
            resize: none;
            text-align: left;

            & > div  {
                display: none;
            }

            span {
                font-size: $sizeText;
                line-height: $lineHeightText;
                height: $lineHeightText;
                display: block;
            }

            @media screen and (max-width: $breakPointDesktop) {
                border: 1px solid $primary;
                padding: 15px;
            }
        }

        .chosen-drop {
            position: absolute;
            left: 0;
            right: 0;
            background-color: $white;
            box-shadow: 0px 12px 24.6px 5.4px rgba(52, 98, 91, 0.1);
            border-radius: 40px;
            padding: 50px;
            z-index: 1;
            opacity: 0;
            visibility: hidden;
            transition: all .3s;

            @media screen and (max-width: $breakPointDesktop) {
                border-radius: 20px;
                padding: 25px;
            }

            .chosen-search {
                margin-bottom: $mbL;

                input {
                    border-left: none;
                    border-right: none;
                    border-top: none;
                    border-radius: 0;
                    padding-left: 0;
                    padding-right: 0;
                }
            }

            .chosen-results {
                position: relative;
                max-height: 240px;
                overflow: hidden;
                overflow-y: auto;
            }
        }

        .chosen-with-drop {
            .chosen-drop {
                opacity: 1;
                visibility: visible;
            }
        }
    }

    &:not(:last-of-type) {
        margin-bottom: $mbL;
    }

    &.contact_file {
        align-items: center;
    }
}

.contact-form {
    max-width: 85%;
    margin: auto;
}

.error-msg {
    position: absolute;
    color: $danger;
    right: 0;
    bottom: -25%;
    font-size: $sizeTextSmall;
}