.rangeSlider {
    border: 2px solid $primary;
    background-color: $white;
    height: 16px;
    width: 100%;
    border-radius: 8px;
    position: relative;
    margin: 22px 0;

    &__fill {
        background-color: $primary;
        height: 100%;
    }

    &__handle {
        width: 60px;
        height: 60px;
        border-radius: 50%;
        background-color: $primary;
        position: absolute;
        top: 50%;
        transform: translateY(-50%);
    }

    @media screen and (max-width: $breakPointDesktop) {
        border-width: 1px;
        height: 8px;
        margin: 11px 0;

        &__handle {
            width: 30px;
            height: 30px;
        }
    }
}

.champ {
    p {
        margin-left: 45px;
        margin-bottom: $lineHeightText;

        @media screen and (max-width: $breakPointDesktop) {
            margin-left: 22px;
        }
    }
}