.section-text-image {
    img {
        width: auto;
        margin: auto;
        max-width: 82%;
    }

    .content {
        padding: 0 50px;

        @media screen and (max-width: $breakPointTablet) {
            padding: 40px 0;
            position: relative;
            z-index: 0;

            h3, p {
                color: $white;
            }

            .btn {
                background-color: transparent;
                color: $white;
                border-color: $white;
            }

            &:before {
                content: "";
                position: absolute;
                left: -30px;
                right: -30px;
                bottom: 0;
                top: 0;
                z-index: -1;
                background-image: -moz-linear-gradient(58deg, rgb(61, 178, 138) 0%, rgb(38, 210, 159) 47%, rgb(15, 242, 179) 100%);
                background-image: -webkit-linear-gradient(58deg, rgb(61, 178, 138) 0%, rgb(38, 210, 159) 47%, rgb(15, 242, 179) 100%);
                background-image: -ms-linear-gradient(58deg, rgb(61, 178, 138) 0%, rgb(38, 210, 159) 47%, rgb(15, 242, 179) 100%);
                box-shadow: 0px 6px 12.3px 2.7px rgba(22, 22, 22, 0.15);
            }
        }
    }

    .btn {
        margin-top: $lineHeightTextM;
    }

    h2 {
        text-align: right;
        font-weight: 400;

        @media screen and (max-width: $breakPointTablet) {
            text-align: left;
            margin-bottom: 16px;
        }
    }

    .col:first-of-type {
        display: flex;
    }

    &.right {
        
        h2 {
            text-align: left;
        }

        .col{

            &:first-of-type {
                display: block;
            }

            &:last-of-type {
                display: flex;
            }
        }
    }

    @media screen and (max-width: $breakPointTablet) {
        .has-image {
            order: 2;
        }
    }

    &.style1 {
        img {
            max-width: 100%
        }

        .row {
            position: relative;
            z-index: 0;
        }

        .content {
            border-radius: 40px;
            background-color: #37ce9d;
            box-shadow: 0px 12px 24.6px 5.4px rgba(22, 22, 22, 0.15);
            padding: 78px 74px;
            margin-left: 15%;

            * {
                color: $white;
            }

            .btn {
                background-color: transparent;
                border-color: $white;
                color: $white;

                &:hover {
                    background-color: $white;
                    color: #37ce9d;
                }
            }

            @media screen and (max-width: $breakPointDesktop){
                border-radius: 20px;
                padding: 38px 35px;
            }
        }

        .has-image {
            padding-top: $sizeH2;
            z-index: -1;
            transform: scaleX(1.1);
            transform-origin: right;
        }
    }
}

.section-text-full {
    .col-lg-10 {
        .row {
            &:not(:last-of-type) {
                margin-bottom: $lineHeightTextXL;

                @media screen and (max-width: $breakPointTablet) {
                    margin-bottom: $lineHeightTextL;
                }
            }
        }
    }

    h3 {
        margin-bottom: 0;
    }

    h4 {
        position: relative;
        top: -20px;
        margin-bottom: 0;

        @media screen and (max-width: $breakPointDesktop) {
            top: -10px;
        }

        @media screen and (max-width: $breakPointTablet) {
            top: 0;
        }
    }
}

.section-text {
    p {
        color: #888d98;
    }
}