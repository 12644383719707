*, *:before, *:after {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html {
    font-size: 100%;
}

body {
    font-family: $font;
    font-size: $sizeText;
    line-height: 1.3;
    font-weight: 400;
    color: $primary;
}

.noscroll {
    position: fixed;
    top: 0;
    overflow: hidden;
}

#wrapper {
    background-image: url(../images/bg-body.png);
    background-repeat: no-repeat;
    background-position: left top;
    background-size: 100% 100%;

    @media screen and (max-width: $breakPointMobile) {
        background-image: url(../images/bg-body-mobile.png);
    }
}

img {
    display: block;
    max-width: 100%;
}

h1 {
    font-size: $sizeH2;
    line-height: 1;
    font-weight: 400;
    margin-bottom: $mbXL;

    span {
        line-height: 1.2;
        display: inline-block;
        background: #0df2b1;
        background: linear-gradient(270deg, #0df2b1 0%, rgba(61, 179, 137, 1) 36%, rgba(29, 145, 106, 1) 52%, #1d916a 72%, rgba(56, 117, 108, 1) 90%, rgba(39, 73, 67, 1) 100%);
        -webkit-background-clip: text;
        -webkit-text-fill-color: transparent;
    }

    @media screen and (max-width: $breakPointMobile) {
        font-size: 48px;

        br {
            display: none;
        }
    }
}

h2 {
    background: #0df2b1;
    background: linear-gradient(270deg, #0df2b1 0%, rgba(61, 179, 137, 1) 36%, rgba(29, 145, 106, 1) 52%, #1d916a 72%, rgba(56, 117, 108, 1) 90%, rgba(39, 73, 67, 1) 100%);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    font-family: $font;
    font-weight: 700;
    font-size: $sizeH2;
    line-height: 1;
    margin-bottom: 97px;

    @media screen and (max-width: $breakPointDesktop) {
        margin-bottom: 48px;
    }
}

h3 {
    font-size: $sizeH3;
    line-height: 1;
    font-weight: 700;
    color: $primary;
    margin-bottom: 43px;

    @media screen and (max-width: $breakPointDesktop) {
        margin-bottom: 21px;
    }
}

h4 {
    font-weight: 700;
    font-size: $sizeH4;
    line-height: 2;
    margin-bottom: 50px;
    color: $primary;

    @media screen and (max-width: $breakPointDesktop) {
        margin-bottom: 25px;
    }
}


h5 {
    font-size: $sizeText;
    line-height: 1.3;
    margin-bottom: $lineHeightText;
}

p + p {
    margin-top: $lineHeightText;
}

a {
    display: inline-block;
    text-decoration: none;
    color: $primary;
}

div.lazy {
    position: absolute;
    left: 0;
    max-width: 82%;
    top: 0;
    bottom: 0;
    width: 100%;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: 100% 100%;
}

.lazy {
    opacity: 0;
    transition: opacity 0.3s;
}

.lazy.loaded {
    opacity: 1;
}

.green {
    color: $second;
}

.section {
    padding: 120px 0;

    @media screen and (max-width: $breakPointDesktop) {
        padding: 60px 0;
    }

    @media screen and (max-width: $breakPointTablet) {
        padding: 50px 0;
    }

    ul {
        li {
            list-style: none;
            padding-left: 50px;
            position: relative;

            &:before {
                content: "";
                position: absolute;
                left: 0;
                top: 5px;
                height: 24px;
                width: 24px;
                background-image: url(../images/icons/ListItem.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% auto;
            }

            @media screen and (max-width: $breakPointDesktop) {
                padding-left: 25px;

                &:before {
                    height: 12px;
                    width: 12px;
                }
            }
        }
    }
}

.text-center {
    text-align: center;
}

.mb-10 {
    margin-bottom:  40px !important;

    @media screen and (max-width: $breakPointDesktop) {
        margin-bottom: 20px !important;
    }
}

.mt-10 {
    margin-top: 40px !important;

    @media screen and (max-width: $breakPointDesktop) {
        margin-top: 20px !important;
    }
}

.mb-20 {
    margin-bottom: 80px !important;

    @media screen and (max-width: $breakPointDesktop) {
        margin-bottom: 40px !important;
    }
}

.mt-20 {
    margin-top: 80px !important;

    @media screen and (max-width: $breakPointDesktop) {
        margin-top: 40px !important;
    }
}

.mb-30 {
    margin-bottom: 120px !important;

    @media screen and (max-width: $breakPointDesktop) {
        margin-bottom: 60px !important;
    }
}

.mt-30 {
    margin-top: 120px !important;

    @media screen and (max-width: $breakPointDesktop) {
        margin-top: 60px !important;
    }
}

.mb-30-s {
    margin-bottom: 60px !important;

    @media screen and (max-width: $breakPointDesktop) {
        margin-bottom: 30px !important;
    }
}

.mb-s {
    margin-bottom: $lineHeightText !important;
}

@media screen and (max-width: $breakPointTablet) {
    .container {
        padding: 0 30px;
    }
}

.pewl {
    max-width: 95% !important;
    background-color: transparent !important;
}
.has-border {
    &__left {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            bottom: 0;
            width: 1px;
            background-image: url(../images/icons/ligne.png);
            background-repeat: no-repeat;
            background-size: 1px 100%;
    
        }
    }

    &__center {
        position: relative;

        &:after {
            content: "";
            position: absolute;
            right: 0;
            top: 0;
            left: 0;
            bottom: 0;
            width: 1px;
            margin: auto;
            background-image: url(../images/icons/ligne.png);
            background-repeat: no-repeat;
            background-size: 1px 100%;
    
        }
    }
}

@import './nav';
@import './header';
@import './footer';
@import './forms';
@import './services';
@import './section-text-image';
@import './info';
@import './grid';
@import './section-tabs';
@import './list_partenaires';


.section-partner {
    .picto {
        img {
            height: 104px;

            @media screen and (max-width: $breakPointDesktop) {
                height: 55px;
            }
        }
    }
}