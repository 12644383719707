.card {
    border-radius: 40px;
    box-shadow: 0px 13px 68.06px 14.94px rgba(47, 94, 86, 0.08);
    padding: 54px 50px;
    position: relative;
    background-color: $white;

    @media screen and (max-width: $breakPointDesktop) {
        padding: 27px 25px;
        border-radius: 20px;
    }

    .date {
        display: inline-block;
        margin-bottom: $lineHeightText;
    }

    .lien {
        margin-top: $lineHeightText;
    }

    .content {
        max-height: 440px;
        overflow: hidden;
        position: relative;
        
        &:after {
            content: "";
            position: absolute;
            left: 0;
            right: 0;
            bottom: 0;
            height: 88px;
            background: linear-gradient(360deg, rgba(255, 255, 255, 1) 14%, rgba(255, 255, 255, 0) 100%);
        }

        @media screen and (max-width: $breakPointDesktop) {
            max-height: 220px;
        }
    }

    @media screen and (max-width: 991px) {
        margin-bottom: 25px;
    }
}