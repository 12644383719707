.garantie:not(label) {
    display: flex;
    margin-top: $mbXL;

    .title-garantie {
        flex: 0 0 50%;
        max-width: 50%;

        p {
            padding-right: 56px;
            position: relative;
            display: inline-block;

            .picto-item {
                position: absolute;
                top: 0;
                bottom: 0;
                right: 0;
                margin: auto;
                width: 30px;
                height: 30px;
                background-image: url(../images/icons/info.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% auto;
            }

            @media screen and (max-width: $breakPointDesktop) {
                padding-right: 28px;

                .picto-item {
                    width: 15px;
                    height: 15px;
                }
            }
        }
    }
    .radio {
        flex:  0 0 25%;
        max-width: 25%;
    }
}