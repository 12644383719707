$font : "NeueHaasUnicaPro";
$font2 : "NeueHaasUnicaW1G";

$primary: #0e1c3f;
$primary-dark: #111b3c;
$primary-light: #9fa1a7;
$white: #FFFFFF;

$second : #32a780;
$secondLight : #0ff2b3;
$danger : #df4759;



$sizeTextSmall : var(--sizeTextSmall);
$sizeTextSmallM : var(--sizeTextSmallM);
$sizeText : var(--sizeText);
$sizeH2 : var(--sizeH2);
$sizeH3 : var(--sizeH3);
$sizeH4 : var(--sizeH4);
$lineHeightText : var(--lineHeightText);
$lineHeightTextM : var(--lineHeightTextM);
$lineHeightTextL : var(--lineHeightTextL);
$lineHeightTextXL : var(--lineHeightTextXL);
$mbXL : var(--mbXL);
$mbL : var(--mbL);
$mb : var(--mb);
$mbS : var(--mbS);

$size : 22px;
$sizeS : 11px;



* {
    --sizeTextSmall: 22px;
    --sizeTextSmallM: 44px;
    --sizeText: 27px;
    --sizeH4: 45px;
    --sizeH2: 132px;
    --sizeH3: 58px;
    --lineHeightText: 35.1px;
    --lineHeightTextM: 70.2px;
    --lineHeightTextL: 105.3px;
    --lineHeightTextXL: 140.0px;
    --mbXL: 80px;
    --mbL: 56px;
    --mbS: 23px;
    --mb: 32px;
}

@media screen and  (max-width: 1919px) {
    * {
        --sizeTextSmall: 11px;
        --sizeTextSmallM: 22px;
        --sizeText: 16px;
        --sizeH4: 23px;
        --sizeH2: 66px;
        --sizeH3: 30px;
        --lineHeightText: 20.8px;
        --lineHeightTextM: 41.6px;
        --lineHeightTextL: 62.4px;
        --lineHeightTextXL: 83.2px;
        --mbXL : 40px;
        --mbL: 27px;
        --mb : 16px;
        --mbS : 12px;
    }
}




$breakPointDesktop: 1919px;
$breakPointTablet: 991px;
$breakPointMobile: 767px;

@function size($size) {
    @return $size * 0.625;
}