.section-tabs {
    #tabs {
        .tab {
            img {
                height: 110px;
                margin: auto;
                margin-bottom: 50px;

                @media screen and (max-width: $breakPointDesktop) {
                    height: 55px;
                    margin-bottom: 25px;
                }
            }

            h3 {
                margin-bottom: 0;
            }

            cursor: pointer;
            padding: 35px 0 200px;
            position: relative;
            
            @media screen and (max-width: $breakPointDesktop) {
                padding: 15px 0 100px;
            }

            &:after {
                content: "";
                position: absolute;
                left: 0;
                right: 0;
                bottom: 60px;
                height: 60px;
                background-image: url(../images/icons/arrow-down.svg);
                background-position: center center;
                background-repeat: no-repeat;
                background-size: auto 60px;
                opacity: 0;
                transition: all .3s;

                @media screen and (max-width: $breakPointDesktop) {
                    bottom: 30px;
                    height: 30px;
                    background-size: auto 30px;
                }
            }

            &.active {
                &:after {
                    opacity: 1;
                }
            }
        }

        
    }
    .item {
        opacity: 0;
        visibility: hidden;
        max-height: 0;
        transition: all .3s;

        &.active {
            opacity: 1;
            visibility: visible;
            max-height: none;
        }
    }

    .contenu-tabs {
        .row {
            position: relative;

            .accordion-header {
                cursor: pointer;
            }

            .accordion-content {
                position: relative;
                opacity: 0;
                visibility: hidden;
                max-height: 0;
                overflow: hidden;
                transition: all 0s;
            }
            &:not(.active) {
                .accordion-header {
                    margin-bottom: 0;
                }
            }

            &.active {
                .accordion-content {
                    opacity: 1;
                    visibility: visible;
                    max-height: 2000px;
                    padding-bottom: 100px;
                    
                    @media screen and (max-width: $breakPointDesktop) {
                        padding-bottom: 50px;
                    }
                }
            }

            &:not(.justify-content-center) {
                padding: 35px 0;

                &:after {
                    content: "";
                    position: absolute;
                    left: calc(var(--bs-gutter-x)/2);
                    right: calc(var(--bs-gutter-x)/2);
                    bottom: 0;
                    background-image: url(../images/icons/ligne-top.png);
                    height: 1px;
                    background-repeat: no-repeat;
                    background-position: center center;
                    background-size: 100% auto;
                }

                @media screen and (max-width: $breakPointDesktop) {
                    padding: 15px 0;
                }
            }

            &.justify-content-center:last-of-type {
                padding-top: 70px;

                @media screen and (max-width: $breakPointDesktop) {
                    padding-top: 35px;
                }
            }
        }
    }
}