.list_partenaires {
    .item {
        margin-bottom: $mbXL;

        a {
            padding-left: 40px;
            position: relative;
            text-decoration: underline;
            margin-top: $lineHeightText;
            
            &:before {
                content: "";
                position: absolute;
                left: 0;
                bottom: 0;
                margin: auto;
                width: 26px;
                height: 26px;
                background-position: center center;
                background-repeat: no-repeat;
                background-size: 100% auto;
                background-image: url(../images/icons/flesh-Btn.svg);
                transform: rotate(180deg);
            }

            @media screen and (max-width: $breakPointDesktop){
                padding-left: 20px;

                &:before {
                    width: 13px;
                    height: 13px;
                }
            }
        }

        .col-image {
            max-width: calc(330px + var(--bs-gutter-x)) ;
            margin-right: 100px;
            display: flex;
            justify-content: center;

            img {
                max-height: 200px;
                width: auto;
            }

            @media screen and (max-width: $breakPointDesktop) {
                max-width: calc(165px + var(--bs-gutter-x));
                margin-right: 50px;

                img {
                    max-height: 100px;
                }
            }

        }
    }
}