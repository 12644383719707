.lien {
    position: relative;
    padding-bottom: 11px;

    &:after {
        content: "";
        position: absolute;
        left: 0;
        right: 0;
        bottom: 0;
        height: 3px;
        background-color: $primary;
    }

    @media screen and (max-width: $breakPointDesktop) {
        padding-bottom: 6px;

        &:after {
            height: 1.5px;
        }
    }
}

.btn {
    font-family: $font;
    font-weight: 400;
    font-size: $sizeText;
    line-height: 1.659;
    background-color: $primary-dark;
    border-radius: 32px;
    border: 3px solid $primary-dark;
    padding: 6px 30px;
    color: $white;
    text-align: center;
    cursor: pointer;
    transition: all .3s;

    &:hover {
        color: $primary;
        background-color: transparent;
    }

    &.light {
        color: $primary;
        background-color: transparent;

        &:hover {
            color: $white;
            background-color: $primary;
        }
    }

    @media screen and (max-width: $breakPointDesktop) {
        border: 1.5px solid $primary-dark;
        border-radius: 15px;
        padding: 3px 15px;
    }

    &.btn-large {
        font-weight: 700;
        border-radius: 64px;
        border: 3px solid $primary-dark;
        padding: 40px 50px;
        min-width: 480px;
    
        @media screen and (max-width: $breakPointDesktop) {
            border: 1.5px solid $primary-dark;
            border-radius: 32px;
            padding: 20px 25px;
            min-width: 240px;
        }
    }

    &.btn-hasImage {
        display: flex;
        align-items: center;
        padding: 21px 55px;

        img {
            margin-right: 40px;
            width: 70px;
        }

        @media screen and (max-width: $breakPointDesktop) {
            padding: 11px 28px;

            img {
                margin-right: 20px;
                width: 35px;
            }
        }
    }
}

.btn-bottom {
    margin-top: 120px;
    text-align: center;

    @media screen and (max-width: $breakPointDesktop) {
        margin-top: 60px;
    }

    @media screen and (max-width: $breakPointTablet) {
        margin-top: 15px;
    }
}