#header {
    text-align: center;

    h4 {
        margin-bottom: $mbXL;
    }

    p:not(:last-of-type) {
        margin-bottom: $mbXL;
    }

    p + p {
        margin-top: 0;
    }

    #calculateur {
        text-align: center;
        max-width: 22.5%;
        margin: auto;

        .input-controle {
            text-align: center;

            label {
                display: block;
                font-size: $sizeH4;
                font-weight: 700;
                line-height: 0.816;
                margin-bottom: calc($mbXL / 2);
            }

            &.results {
                label {
                    font-size: $sizeText;
                    font-weight: 400;
                    margin-bottom: $mb;
                }
            }
        }

        input {
            width: 100%;
        }
    }

    .calculateur_homepage #calculateur {
        max-width: 34.5%;
    }

    @media screen and (max-width: $breakPointMobile) {
        h4 {
            display: none;
        }
    }
}